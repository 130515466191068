import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";

import { getTimeLabelFromFirestoreTimestamp } from "../../utils";
import { useGetToDos } from "./hooks";
import { FirebaseContext } from "../../Firebase";
import { UserProfileContext } from "../../context";

const ToDoPage = () => {
  const firebase = useContext(FirebaseContext);
  const userProfile = useContext(UserProfileContext);
  const todos = useGetToDos(firebase, userProfile ? userProfile.id : null);
  //console.log("ToDoPage::todos: ", todos);
  return (
    <Row>
      <Col>
        <h1>Текущие задачи</h1>
        {todos.length === 0 && (
          <p>
            Задач нет. Они появятся по мере оказания консультации и заершения их
            самых.{" "}
          </p>
        )}
        {todos.length > 0 && <ToDoTable todoDataList={todos} />}
      </Col>
    </Row>
  );
};

export default ToDoPage;

const ToDoTable = ({ todoDataList }) => {
  let history = useHistory();
  const rows = todoDataList.map(todo => {
    const { client, createdAt } = todo;
    const tsLabel = getTimeLabelFromFirestoreTimestamp(createdAt);
    return (
      <tr
        onClick={() =>
          history.push(`/todo/${todo.id}`, {
            consultationId: todo.consultationId,
            clientData: client
          })
        }
      >
        <td>{tsLabel}</td>
        <td>{client.name}</td>
        <td>{client.birthday}</td>
        <td>{client.sex}</td>
      </tr>
    );
  });
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Имя</th>
          <th>полных лет</th>
          <th>пол</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};
