import React, { useEffect } from "react";
import shave from "shave";

import "./ConversationListItem.css";

export default function ConversationListItem(props) {
  const { data, isActive, onItemClicked } = props;
  useEffect(() => {
    shave(".conversation-snippet", 30);
  });

  const { name, text } = props.data;
  const className = isActive
    ? "conversation-list-item conversation-list-item-active"
    : "conversation-list-item";
  return (
    <div
      className={className}
      onClick={() => {
        onItemClicked(data.id);
      }}
    >
      <div className="conversation-info">
        <h1 className="conversation-title">{name}</h1>
        <p className="conversation-snippet">{text}</p>
      </div>
    </div>
  );
}
