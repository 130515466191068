import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const EducationCard = props => {
  const { endYear, schoolName, id } = props.data;

  return (
    <Card className="mt-2">
      <Card.Body>
        <Card.Title>{schoolName ? schoolName : "Не указано"}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {endYear ? endYear : "----"}
        </Card.Subtitle>

        <Card.Link as={Link} to={`/userprofile/education/${id}/edit/`}>
          Редактировать
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

export default EducationCard;
