import { useEffect, useState } from "react";

const INITIAL_STATE = {
  endYear: "",
  schoolName: "",
  type: "",
  specialization: ""
};
export const useHandleFormUpdate = (db, profileId, docId) => {
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [submitting, setSubmitting] = useState(false);

  const handleDataChange = e => {
    const key = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [key]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);
  };

  useEffect(() => {
    if (db && profileId && docId) {
      db.doc(`specialist/${profileId}/education/${docId}`)
        .get()
        .then(docSnapshot =>
          setFormValues({ id: docSnapshot.id, ...docSnapshot.data() })
        )
        .catch(err => console.error("Error: ", err));
    }
  }, [db, profileId, docId]);

  useEffect(() => {
    if (submitting && db && profileId && docId) {
      db.doc(`specialist/${profileId}/education/${docId}`).update(formValues);
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }
  }, [submitting, db, profileId, docId]);

  return { handleDataChange, handleSubmit, formValues, submitting };
};
