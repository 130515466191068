import { useEffect, useState } from "react";

const useListenUserAuthState = auth => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (auth) {
      return auth.onAuthStateChanged(function(user) {
        setUser(user);
      });
    }
  }, [auth]);
  return user;
};

export { useListenUserAuthState };

const useSpecialistProfile = firebase => {
  const [profile, setProfile] = useState(undefined);
  useEffect(() => {
    if (firebase && firebase.auth.currentUser) {
      firebase.db
        .collection("specialist")
        .where("email", "==", firebase.auth.currentUser.email)
        .onSnapshot(querySnapshot => {
          if (!querySnapshot.empty) {
            console.log("Profile data: ", querySnapshot.docs[0].data());
            setProfile({
              id: querySnapshot.docs[0].id,
              ...querySnapshot.docs[0].data()
            });
          } else setProfile(null);
        });
    }
  }, [firebase, firebase.auth.currentUser]);
  return profile;
};

export { useSpecialistProfile };

export const useGetActiveChatsWIthProfileId = (id, firebase) => {
  const [activeChats, setActiveChats] = useState([]);
  console.log("useGetActiveChatsWIthProfileId 1 : ", id);
  useEffect(() => {
    if (!!firebase && !!id) {
      console.log("useGetActiveChatsWIthProfileId: ", id);
      firebase.db
        .collection("chats")
        .where("specialistId", "==", id)
        .where("finished", "==", false)
        .onSnapshot(querySnapshot => {
          let tempArr = [];
          querySnapshot.forEach(doc => {
            tempArr.push({ id: doc.id, ...doc.data() });
          });
          setActiveChats([...tempArr]);
        });
    }
  }, [id]);
  return activeChats;
};
