import React, { useContext } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";

import { FirebaseContext } from "../../../Firebase";
import { useSaveRecommendation } from "./hooks";

const ToDoDetailPage = () => {
  const firebase = useContext(FirebaseContext);

  const { todoId } = useParams();
  const location = useLocation();
  const consultationId = location.state.consultationId;
  const clientData = location.state.clientData;

  const {
    setTestsRecommendation,
    setRealConsultationRecommendation,
    setOtherRecommendation,
    canSubmit,
    saveData,
    submitting,
    success
  } = useSaveRecommendation(consultationId, todoId, firebase);

  const submitForm = e => {
    e.preventDefault();
    saveData();
  };
  return (
    <Row>
      <Col>
        <h1>Рекомендации к консультации </h1>
        <h2>
          {clientData.name}, {clientData.birthday}, {clientData.sex}
        </h2>
        <Form onSubmit={submitForm}>
          <Form.Group controlId="formToDo">
            <Form.Label>Рекомендуемые анализы</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              onChange={event => setTestsRecommendation(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formToDo">
            <Form.Label>Рекомендации по консультациям</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              onChange={event =>
                setRealConsultationRecommendation(event.target.value)
              }
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Общие рекомендации</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              onChange={event => setOtherRecommendation(event.target.value)}
            />
          </Form.Group>

          {success && (
            <Alert variant="success">
              Ваши рекомендации сохранены и доступны пациенту.
            </Alert>
          )}
          <Button
            variant="primary"
            type="submit"
            disabled={!canSubmit || submitting}
          >
            {submitting && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Отправить
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default ToDoDetailPage;
