import React, { useEffect, useState } from "react";
import ConversationList from "../ConversationList";
import MessageList from "../MessageList";
import "./Messenger.css";

export default function Messenger(props) {
  const {
    activeChats,
    messageList,
    onChatIdChange,
    currChat,
    sendMessage,
    finishConsultation,
    userProfile
  } = props;
  const currChatId = currChat ? currChat.id : null;
  return (
    <div className="messenger">
      <div className="scrollable sidebar">
        <ConversationList
          activeConversationId={currChatId}
          conversationList={activeChats}
          convsersationItemClicked={onChatIdChange}
        />
      </div>

      <div className="scrollable content">
        {currChat && (
          <MessageList
            activeConversationId={currChatId}
            messageList={messageList}
            sendMessage={sendMessage}
            userProfile={userProfile}
            finishConsultation={finishConsultation}
          />
        )}
      </div>
    </div>
  );
}
