import React, { useEffect } from "react";
import { Image, Row, Col } from "react-bootstrap";
import moment from "moment";
import "./Message.css";

export default function Message(props) {
  const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;
  const ref = React.createRef();

  const handleClick = () =>
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });

  useEffect(() => {
    if (ref) {
      handleClick();
    }
  }, []);

  const friendlyTimestamp = data.timestamp
    ? moment.unix(data.timestamp.seconds).format("LLLL")
    : "";
  return (
    <div
      className={[
        "message",
        `${isMine ? "mine" : ""}`,
        `${startsSequence ? "start" : ""}`,
        `${endsSequence ? "end" : ""}`
      ].join(" ")}
      ref={ref}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className={data.url ? "" : "bubble-container"}>
        <div className={data.url ? "" : "bubble"} title={friendlyTimestamp}>
          {data.url ? (
            <Row className={!isMine ? "" : "justify-content-end"}>
              <Col xs={8} md={4} lg={2}>
                <Image src={data.url} thumbnail />
              </Col>
            </Row>
          ) : (
            data.message
          )}
        </div>
      </div>
    </div>
  );
}
