import { useState, useEffect } from "react";

export const useGetToDos = (firebase, specialistProfileId) => {
  const [todoList, setTodoList] = useState([]);
  useEffect(() => {
    if (firebase && specialistProfileId) {
      //console.log("useGetToDos::specialistProfileId: ", specialistProfileId);
      firebase.db
        .collectionGroup("specialistRecommendation")
        .where("isPendingSpecialist", "==", true)
        .where("specialistId", "==", specialistProfileId)
        .get()
        .then(qSnapshot => {
          const result = [];
          if (!qSnapshot.empty) {
            qSnapshot.docs.forEach(pendingRecomendation => {
              result.push({
                id: pendingRecomendation.id,
                consultationId: pendingRecomendation.ref.parent.parent.id,
                ...pendingRecomendation.data()
              });
            });
            //console.log('!!!!!!!!!Result" ', result);
            setTodoList(result);
          }
        });
    }
  }, [firebase, specialistProfileId]);
  return todoList;
};
