import React, { useState, useEffect } from "react";
//import ConversationSearch from "../ConversationSearch";
import ConversationListItem from "../ConversationListItem";
import Toolbar from "../Toolbar";
//import ToolbarButton from "../ToolbarButton";

import "./ConversationList.css";

const moment = require("moment");
export default function ConversationList(props) {
  const {
    conversationList,
    activeConversationId,
    convsersationItemClicked
  } = props;

  const [conversations, setConversations] = useState([]);
  useEffect(() => {
    getConversations();
  }, [conversationList]);

  const getConversations = () => {
    let newConversations = conversationList.map(result => {
      const birthday = result.client.birthday;
      const fullYears = moment().diff(birthday, "years");
      return {
        id: result.id,

        name: result.client.name,
        text: `${fullYears} (${result.client.sex})`
      };
    });
    setConversations([...newConversations]);
  };

  useEffect(() => {
    if (conversationList.length && !activeConversationId) {
      const firstConversationId = conversationList[0].id;
      convsersationItemClicked(firstConversationId);
    }
  }, [conversationList]);

  //console.log("conversations: ", conversations);
  return (
    <div className="conversation-list">
      <Toolbar title="Текущие чаты" />

      {conversations.map(conversation => (
        <ConversationListItem
          key={conversation.name}
          isActive={activeConversationId === conversation.id}
          data={conversation}
          onItemClicked={convsersationItemClicked}
        />
      ))}
    </div>
  );
}
const results = [
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Michael",
      last: "Clarke"
    },
    location: {
      street: {
        number: 2493,
        name: "Alexandra Street"
      },
      city: "Auckland",
      state: "Bay of Plenty",
      country: "New Zealand",
      postcode: 77556,
      coordinates: {
        latitude: "62.2928",
        longitude: "-37.1492"
      },
      timezone: {
        offset: "+4:00",
        description: "Abu Dhabi, Muscat, Baku, Tbilisi"
      }
    },
    email: "michael.clarke@example.com",
    login: {
      uuid: "c193001c-7a21-41ac-9163-9001ac1259ab",
      username: "happyduck618",
      password: "bass",
      salt: "BPkfePWL",
      md5: "1085e0cd38fc488eb5d45fddd425ce81",
      sha1: "0db88f3fbf504ba3760331e90b830e485575d534",
      sha256: "e1b97e8edd681bbb236307393c802e5d1d6c0590c87e5ba05d821318aee6a878"
    },
    dob: {
      date: "1944-09-22T04:02:18.889Z",
      age: 75
    },
    registered: {
      date: "2002-04-26T23:57:06.787Z",
      age: 17
    },
    phone: "(671)-136-6789",
    cell: "(917)-330-7191",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/8.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/8.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/8.jpg"
    },
    nat: "NZ"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "علی رضا",
      last: "یاسمی"
    },
    location: {
      street: {
        number: 5202,
        name: "استاد قریب"
      },
      city: "ایلام",
      state: "بوشهر",
      country: "Iran",
      postcode: 37338,
      coordinates: {
        latitude: "56.1365",
        longitude: "164.0499"
      },
      timezone: {
        offset: "+4:30",
        description: "Kabul"
      }
    },
    email: "aalyrd.ysmy@example.com",
    login: {
      uuid: "f1b651b2-e2a5-4afa-8123-4136c9a6557d",
      username: "heavyswan723",
      password: "fellatio",
      salt: "enxVLNzu",
      md5: "cd36b7cc29ecd16d06da23919355b0e6",
      sha1: "ed3c748f38c79fe6c326703540d83e5b58d17d37",
      sha256: "5dc51aec3a9177e29a627450fc2da9dacf34a564e22dc5208a3ec36607687d7a"
    },
    dob: {
      date: "1956-01-16T13:49:41.404Z",
      age: 63
    },
    registered: {
      date: "2006-05-19T13:48:56.580Z",
      age: 13
    },
    phone: "058-76977431",
    cell: "0943-502-4270",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/36.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/36.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/36.jpg"
    },
    nat: "IR"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Charles",
      last: "Lo"
    },
    location: {
      street: {
        number: 8521,
        name: "College Ave"
      },
      city: "Killarney",
      state: "Northwest Territories",
      country: "Canada",
      postcode: "X0V 8O6",
      coordinates: {
        latitude: "34.7871",
        longitude: "-104.1922"
      },
      timezone: {
        offset: "+3:30",
        description: "Tehran"
      }
    },
    email: "charles.lo@example.com",
    login: {
      uuid: "358eb9fc-c663-4658-8464-c123399d4771",
      username: "brownbird109",
      password: "deerhunt",
      salt: "mq9Fzina",
      md5: "f39eaf26a5fa053b637d2b296eb0113e",
      sha1: "1a303b23d0297880abdf451b1ff177a4c0dd5817",
      sha256: "e441913cdc22d23b16d833488e92d29f22fbe7b9be1676967e2a21705c62c142"
    },
    dob: {
      date: "1984-08-14T16:02:57.720Z",
      age: 35
    },
    registered: {
      date: "2010-04-04T17:12:36.400Z",
      age: 9
    },
    phone: "520-660-3795",
    cell: "106-441-0418",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/55.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/55.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/55.jpg"
    },
    nat: "CA"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Max",
      last: "Morris"
    },
    location: {
      street: {
        number: 1354,
        name: "Pioneer Highway"
      },
      city: "Dunedin",
      state: "Marlborough",
      country: "New Zealand",
      postcode: 26160,
      coordinates: {
        latitude: "-54.9987",
        longitude: "-86.0041"
      },
      timezone: {
        offset: "+9:30",
        description: "Adelaide, Darwin"
      }
    },
    email: "max.morris@example.com",
    login: {
      uuid: "f8623043-00b7-4884-9990-6cf920a77207",
      username: "whiteleopard983",
      password: "pinetree",
      salt: "7NOwrkUQ",
      md5: "d6c8c73f009775503639fff130254d25",
      sha1: "38b3e87506b77f26f3337f8d6deda46bed03655c",
      sha256: "679ecdf0e4c85df947ab088b77aa7644ff180e24c16b0298ec13cd0189aa9997"
    },
    dob: {
      date: "1971-11-14T15:47:31.628Z",
      age: 48
    },
    registered: {
      date: "2013-03-02T14:10:12.483Z",
      age: 6
    },
    phone: "(071)-428-1911",
    cell: "(707)-112-9821",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/69.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/69.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/69.jpg"
    },
    nat: "NZ"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Rayan",
      last: "Laurent"
    },
    location: {
      street: {
        number: 6331,
        name: "Route de Genas"
      },
      city: "Le Havre",
      state: "Alpes-de-Haute-Provence",
      country: "France",
      postcode: 53549,
      coordinates: {
        latitude: "-62.5189",
        longitude: "109.9761"
      },
      timezone: {
        offset: "+11:00",
        description: "Magadan, Solomon Islands, New Caledonia"
      }
    },
    email: "rayan.laurent@example.com",
    login: {
      uuid: "e0dd8ad2-b8e2-4a26-afaa-542cabe8f8ab",
      username: "organictiger392",
      password: "ncc1701d",
      salt: "k3WPeSEj",
      md5: "57516125b21a9ada1ed3938c0c4e92e0",
      sha1: "6eca3c609902dfd02373b8bbb7f7d5d8de72bf44",
      sha256: "99719f5c68db6ef54205c9f415bf27ad1d3aa787c5baef82bd7df0e5b0749d4f"
    },
    dob: {
      date: "1948-03-15T16:35:42.667Z",
      age: 71
    },
    registered: {
      date: "2008-01-25T14:07:55.752Z",
      age: 11
    },
    phone: "02-73-10-19-18",
    cell: "06-92-93-63-71",
    id: {
      name: "INSEE",
      value: "1NNaN82716898 65"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/23.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/23.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/23.jpg"
    },
    nat: "FR"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "آرسین",
      last: "نكو نظر"
    },
    location: {
      street: {
        number: 4074,
        name: "شهید بهشتی"
      },
      city: "نجف‌آباد",
      state: "سیستان و بلوچستان",
      country: "Iran",
      postcode: 40829,
      coordinates: {
        latitude: "-85.3281",
        longitude: "104.1396"
      },
      timezone: {
        offset: "-11:00",
        description: "Midway Island, Samoa"
      }
    },
    email: "arsyn.nkwnzr@example.com",
    login: {
      uuid: "173e2f22-c1c1-424e-9327-0c7fa56072dc",
      username: "heavyzebra122",
      password: "storms",
      salt: "6V2seuP9",
      md5: "33c29617d84426428cbf3f7dda6e80f3",
      sha1: "27bbb9114b93f8a5376d40079d6bb3741a42ff12",
      sha256: "628970e111c5595055141a64828b15d5530b0f38ac1cb24b239718b9ead7445b"
    },
    dob: {
      date: "1965-11-28T17:25:55.741Z",
      age: 54
    },
    registered: {
      date: "2014-12-05T03:54:48.486Z",
      age: 5
    },
    phone: "091-27392820",
    cell: "0909-961-6194",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/74.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/74.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/74.jpg"
    },
    nat: "IR"
  },
  {
    gender: "female",
    name: {
      title: "Ms",
      first: "Melodie",
      last: "Knight"
    },
    location: {
      street: {
        number: 4905,
        name: "Oak St"
      },
      city: "Fountainbleu",
      state: "Yukon",
      country: "Canada",
      postcode: "G9G 9K0",
      coordinates: {
        latitude: "30.7685",
        longitude: "-156.5696"
      },
      timezone: {
        offset: "+4:30",
        description: "Kabul"
      }
    },
    email: "melodie.knight@example.com",
    login: {
      uuid: "b72a9e51-533b-4511-af2e-f3f5a4c1d4c2",
      username: "sadelephant771",
      password: "ginger1",
      salt: "8m39Ozgm",
      md5: "85b993c7002e15ea4eb39eb6502429d7",
      sha1: "45f6861f16cf239ddb578c9c9b8cb8dcabe5ebc6",
      sha256: "503a9ce59badabdd971b9cfc98f24fc248158770a4bc885dd7c44653d8491cce"
    },
    dob: {
      date: "1982-06-04T20:19:03.260Z",
      age: 37
    },
    registered: {
      date: "2006-07-24T00:36:18.996Z",
      age: 13
    },
    phone: "545-039-6636",
    cell: "748-353-5313",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/74.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/74.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/74.jpg"
    },
    nat: "CA"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Matheo",
      last: "Arnøy"
    },
    location: {
      street: {
        number: 7321,
        name: "Bentsebrugata"
      },
      city: "Ådalsbruk",
      state: "Vest-Agder",
      country: "Norway",
      postcode: "2020",
      coordinates: {
        latitude: "22.9753",
        longitude: "138.6733"
      },
      timezone: {
        offset: "+4:30",
        description: "Kabul"
      }
    },
    email: "matheo.arnoy@example.com",
    login: {
      uuid: "edaaa51b-066c-475e-8d41-5afd7f06bbe2",
      username: "lazyelephant789",
      password: "1234567",
      salt: "0glqu6De",
      md5: "eaba7034552499e254e88e6202d2bcb4",
      sha1: "61c97c83feea1b7ff26f1580d3adedab47dc3e79",
      sha256: "369b951f84bd1fa52c0cc2ffd0e50d7e3e3b537f1b7832aff85cb585bc1b5c90"
    },
    dob: {
      date: "1951-09-18T19:06:03.001Z",
      age: 68
    },
    registered: {
      date: "2003-08-15T06:32:14.689Z",
      age: 16
    },
    phone: "54488034",
    cell: "91484271",
    id: {
      name: "FN",
      value: "18095145589"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/65.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/65.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/65.jpg"
    },
    nat: "NO"
  },
  {
    gender: "female",
    name: {
      title: "Ms",
      first: "Eden",
      last: "Gonzalez"
    },
    location: {
      street: {
        number: 7292,
        name: "Rue de la Fontaine"
      },
      city: "Le Havre",
      state: "Pyrénées-Atlantiques",
      country: "France",
      postcode: 67188,
      coordinates: {
        latitude: "-44.2461",
        longitude: "75.6995"
      },
      timezone: {
        offset: "-7:00",
        description: "Mountain Time (US & Canada)"
      }
    },
    email: "eden.gonzalez@example.com",
    login: {
      uuid: "5f4e300e-fa3a-42e7-a9d7-fa7a5edf3dfa",
      username: "blackpeacock649",
      password: "shadow",
      salt: "nNI5KbvA",
      md5: "a7f5b6b9f9eb4f08eecb48b35e5d1005",
      sha1: "1bd30a08c33a6ee8e889d89bae218f41cf690db0",
      sha256: "e52da28df810835b4aa01a9a9f529b04f0155104a4def9f3fad9774741842578"
    },
    dob: {
      date: "1957-04-27T16:49:49.182Z",
      age: 62
    },
    registered: {
      date: "2015-05-04T01:37:56.044Z",
      age: 4
    },
    phone: "04-17-48-91-28",
    cell: "06-65-02-35-96",
    id: {
      name: "INSEE",
      value: "2NNaN44666581 42"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/7.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/7.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/7.jpg"
    },
    nat: "FR"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Rúpio",
      last: "Rezende"
    },
    location: {
      street: {
        number: 1966,
        name: "Rua Onze "
      },
      city: "Pindamonhangaba",
      state: "Roraima",
      country: "Brazil",
      postcode: 95002,
      coordinates: {
        latitude: "-16.9452",
        longitude: "-140.4429"
      },
      timezone: {
        offset: "+9:00",
        description: "Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
      }
    },
    email: "rupio.rezende@example.com",
    login: {
      uuid: "36b3bb3c-8690-45fa-949c-7d68694244e4",
      username: "whitegorilla506",
      password: "springs",
      salt: "LsNOaeGI",
      md5: "7796215e36bc8b4783da6885fbb62774",
      sha1: "d7854ee20febe84d2b5dcc1e3657d945674db764",
      sha256: "b5bf98ac6f5e1c5671c21c8d86a6a33e987f6156ea50962613de3c78c032ec52"
    },
    dob: {
      date: "1983-02-18T23:00:11.991Z",
      age: 36
    },
    registered: {
      date: "2014-01-03T16:48:14.491Z",
      age: 5
    },
    phone: "(02) 9432-4614",
    cell: "(49) 7131-3895",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/23.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/23.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/23.jpg"
    },
    nat: "BR"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Kaïs",
      last: "Dubois"
    },
    location: {
      street: {
        number: 4380,
        name: "Rue Paul Bert"
      },
      city: "Paris",
      state: "Allier",
      country: "France",
      postcode: 83857,
      coordinates: {
        latitude: "86.8520",
        longitude: "-28.9826"
      },
      timezone: {
        offset: "-7:00",
        description: "Mountain Time (US & Canada)"
      }
    },
    email: "kais.dubois@example.com",
    login: {
      uuid: "0d090ab0-fe2f-4134-9e71-083c21a677dd",
      username: "heavydog455",
      password: "griffey",
      salt: "tIgPpmlx",
      md5: "4df7411eea6e17f8c684b8d6b820cb45",
      sha1: "f0068c3e30b34b39caac6cf9a1c77afaf8c3b223",
      sha256: "828a8dc73f0211a5b2f546170741663f6f830d416fc9f8a3f70607f0fd420be4"
    },
    dob: {
      date: "1956-02-10T08:35:52.975Z",
      age: 63
    },
    registered: {
      date: "2003-01-29T19:33:45.782Z",
      age: 16
    },
    phone: "01-72-11-07-59",
    cell: "06-28-57-20-61",
    id: {
      name: "INSEE",
      value: "1NNaN05854005 64"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/87.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/87.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/87.jpg"
    },
    nat: "FR"
  },
  {
    gender: "female",
    name: {
      title: "Miss",
      first: "Maria",
      last: "Phillips"
    },
    location: {
      street: {
        number: 6608,
        name: "Park Lane"
      },
      city: "Portsmouth",
      state: "Norfolk",
      country: "United Kingdom",
      postcode: "WQ4 6JS",
      coordinates: {
        latitude: "-82.4343",
        longitude: "-53.1803"
      },
      timezone: {
        offset: "0:00",
        description: "Western Europe Time, London, Lisbon, Casablanca"
      }
    },
    email: "maria.phillips@example.com",
    login: {
      uuid: "6827c73b-3a13-47f4-ab2b-a6ca3a8e9056",
      username: "organicbird860",
      password: "enternow",
      salt: "fZk5Gete",
      md5: "66b8da6ce61b9bbdc6b5dcd9cab7d91d",
      sha1: "4d199b131c9726308791f8633ec41e10aa0254f7",
      sha256: "377e78251986db7aa55257913cb03b6f9da6539eed67640ab242d96155b74c83"
    },
    dob: {
      date: "1981-02-14T14:24:44.363Z",
      age: 38
    },
    registered: {
      date: "2018-09-16T07:38:46.041Z",
      age: 1
    },
    phone: "015394 22407",
    cell: "0788-126-441",
    id: {
      name: "NINO",
      value: "PK 76 90 14 J"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/55.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/55.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/55.jpg"
    },
    nat: "GB"
  },
  {
    gender: "female",
    name: {
      title: "Ms",
      first: "Emmi",
      last: "Saari"
    },
    location: {
      street: {
        number: 498,
        name: "Itsenäisyydenkatu"
      },
      city: "Myrskylä",
      state: "Central Ostrobothnia",
      country: "Finland",
      postcode: 56846,
      coordinates: {
        latitude: "53.5483",
        longitude: "-129.9985"
      },
      timezone: {
        offset: "-5:00",
        description: "Eastern Time (US & Canada), Bogota, Lima"
      }
    },
    email: "emmi.saari@example.com",
    login: {
      uuid: "c93b1b1b-7cf2-43e9-8045-8fa779ab8e17",
      username: "tinycat958",
      password: "test",
      salt: "qOdwpCXb",
      md5: "e9c771d512a6413a156e8ba4f3b56990",
      sha1: "13491514c5b2e25e29630d97cab7f738829d9fd1",
      sha256: "4a717f7d736379a897aa1b478c0ea86bc380689d116b3d1a8e47f85001131df4"
    },
    dob: {
      date: "1973-11-20T03:15:32.896Z",
      age: 46
    },
    registered: {
      date: "2011-10-07T03:39:56.951Z",
      age: 8
    },
    phone: "02-390-296",
    cell: "040-493-98-43",
    id: {
      name: "HETU",
      value: "NaNNA740undefined"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/40.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/40.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/40.jpg"
    },
    nat: "FI"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Benjamin",
      last: "Morin"
    },
    location: {
      street: {
        number: 9772,
        name: "Argyle St"
      },
      city: "Campbellton",
      state: "New Brunswick",
      country: "Canada",
      postcode: "Y8K 0W1",
      coordinates: {
        latitude: "-39.4448",
        longitude: "-153.7300"
      },
      timezone: {
        offset: "+4:00",
        description: "Abu Dhabi, Muscat, Baku, Tbilisi"
      }
    },
    email: "benjamin.morin@example.com",
    login: {
      uuid: "1dde37ad-f94a-4a01-88de-67ba313e7546",
      username: "goldenlion124",
      password: "petra",
      salt: "xAHFlaEt",
      md5: "df20f2d6f1202de4a1daac71541e9654",
      sha1: "6a057fa8887ab9540d0e6d114e01d3dd4b038bdb",
      sha256: "a3881bf859e5005413a2c9acced402fd98baf12a87c30e858147fc3312c0bdc4"
    },
    dob: {
      date: "1968-08-22T01:48:16.006Z",
      age: 51
    },
    registered: {
      date: "2011-04-30T20:14:39.430Z",
      age: 8
    },
    phone: "036-649-3072",
    cell: "360-592-3133",
    id: {
      name: "",
      value: null
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/14.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/14.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/14.jpg"
    },
    nat: "CA"
  },
  {
    gender: "female",
    name: {
      title: "Miss",
      first: "Lucille",
      last: "Montgomery"
    },
    location: {
      street: {
        number: 5549,
        name: "Westheimer Rd"
      },
      city: "Cedar Rapids",
      state: "Arkansas",
      country: "United States",
      postcode: 95868,
      coordinates: {
        latitude: "25.6285",
        longitude: "-128.0326"
      },
      timezone: {
        offset: "-8:00",
        description: "Pacific Time (US & Canada)"
      }
    },
    email: "lucille.montgomery@example.com",
    login: {
      uuid: "76fb2526-df98-4363-a7fa-0d581a432567",
      username: "beautifulgorilla784",
      password: "louise",
      salt: "mNfSV8Sd",
      md5: "7a889244c73271e6a471c10143b4e7c8",
      sha1: "2422fa1aec5dd07b414e96e10a21d1929d11ebd4",
      sha256: "1ec816618cbec8bcdd32a03f9c0d45b544be12aaaff7066e6867272362cb2c35"
    },
    dob: {
      date: "1963-06-28T09:22:25.997Z",
      age: 56
    },
    registered: {
      date: "2004-10-17T07:16:01.660Z",
      age: 15
    },
    phone: "(088)-185-6747",
    cell: "(882)-139-1929",
    id: {
      name: "SSN",
      value: "085-36-6050"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/84.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/84.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/84.jpg"
    },
    nat: "US"
  },
  {
    gender: "male",
    name: {
      title: "Monsieur",
      first: "Fabian",
      last: "Boyer"
    },
    location: {
      street: {
        number: 9565,
        name: "Rue des Jardins"
      },
      city: "Cugy (Vd)",
      state: "Obwalden",
      country: "Switzerland",
      postcode: 8420,
      coordinates: {
        latitude: "-57.6544",
        longitude: "-37.9525"
      },
      timezone: {
        offset: "+5:30",
        description: "Bombay, Calcutta, Madras, New Delhi"
      }
    },
    email: "fabian.boyer@example.com",
    login: {
      uuid: "30e1b312-1c8e-44d8-b40a-319d97108a98",
      username: "heavylion679",
      password: "hotbox",
      salt: "Y4rNm6RS",
      md5: "42fa1ff01e5898bb2c94a539750507a6",
      sha1: "13a2d30b6ea37e43c295e9adfd4088f68dcaa1e3",
      sha256: "e8dbf69ab3990ccc88a99127905855b8aa55672ebeafe5fb0fe8e97d57309598"
    },
    dob: {
      date: "1952-12-12T05:55:12.718Z",
      age: 67
    },
    registered: {
      date: "2015-11-25T13:18:16.651Z",
      age: 4
    },
    phone: "077 363 78 72",
    cell: "076 779 22 59",
    id: {
      name: "AVS",
      value: "756.0136.6710.99"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/14.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/14.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/14.jpg"
    },
    nat: "CH"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Tjitze",
      last: "Oortwijn"
    },
    location: {
      street: {
        number: 680,
        name: "Hoenlo"
      },
      city: "Sittard",
      state: "Zuid-Holland",
      country: "Netherlands",
      postcode: 10323,
      coordinates: {
        latitude: "36.6233",
        longitude: "60.2738"
      },
      timezone: {
        offset: "-9:00",
        description: "Alaska"
      }
    },
    email: "tjitze.oortwijn@example.com",
    login: {
      uuid: "b17a9aaf-1d2e-4814-9591-daf9c1c9593f",
      username: "tinymouse502",
      password: "jonathan",
      salt: "n22CT30m",
      md5: "cf577f0ec53eb5883184fb2fc546e5bc",
      sha1: "f93a418a5a70d5d028f78bc6213609f57b8a3f91",
      sha256: "409d318d06c6c55c40b00bbd5d134a29b6c62c3d5cbf0dbbc6e1cd81104a30ff"
    },
    dob: {
      date: "1989-04-02T14:22:13.696Z",
      age: 30
    },
    registered: {
      date: "2007-01-17T23:03:41.619Z",
      age: 12
    },
    phone: "(814)-712-6685",
    cell: "(066)-065-8485",
    id: {
      name: "BSN",
      value: "70991261"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/29.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/29.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/29.jpg"
    },
    nat: "NL"
  },
  {
    gender: "male",
    name: {
      title: "Mr",
      first: "Ross",
      last: "Boyd"
    },
    location: {
      street: {
        number: 9509,
        name: "Grange Road"
      },
      city: "Tralee",
      state: "Monaghan",
      country: "Ireland",
      postcode: 95315,
      coordinates: {
        latitude: "-46.9490",
        longitude: "143.9723"
      },
      timezone: {
        offset: "-3:30",
        description: "Newfoundland"
      }
    },
    email: "ross.boyd@example.com",
    login: {
      uuid: "2d7ecfc7-857a-4c85-9c90-0d959451dda6",
      username: "purplebird922",
      password: "biggles",
      salt: "0gzPqkY7",
      md5: "4b69e41578639f98bec8d2de39af2ac1",
      sha1: "6dfa9e5d749a5762004cb401da02cce166bf2f3d",
      sha256: "5b15baa85e8d077a252118944d71c5bcb191f3d2857ae26b9148ebbfe3babdbb"
    },
    dob: {
      date: "1950-03-15T03:15:46.681Z",
      age: 69
    },
    registered: {
      date: "2016-01-24T16:07:53.560Z",
      age: 3
    },
    phone: "011-556-3151",
    cell: "081-123-2375",
    id: {
      name: "PPS",
      value: "8343435T"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/men/0.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/0.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/0.jpg"
    },
    nat: "IE"
  },
  {
    gender: "female",
    name: {
      title: "Miss",
      first: "Judy",
      last: "White"
    },
    location: {
      street: {
        number: 533,
        name: "Richmond Park"
      },
      city: "Kells",
      state: "Cavan",
      country: "Ireland",
      postcode: 24926,
      coordinates: {
        latitude: "-53.5098",
        longitude: "142.2686"
      },
      timezone: {
        offset: "+6:00",
        description: "Almaty, Dhaka, Colombo"
      }
    },
    email: "judy.white@example.com",
    login: {
      uuid: "a0b4370b-8af4-43df-b6b3-20880b13d20b",
      username: "lazyrabbit293",
      password: "biguns",
      salt: "zp9Pud9U",
      md5: "71e22dea9b51b01c43fcd399bfbaa232",
      sha1: "71400ddcd91534be88c6616ce430c02a9569098a",
      sha256: "c17f50d97a128343c901b7c5948d7af6bc3d3e8bd59b45ae33549bd762d1e1f0"
    },
    dob: {
      date: "1962-10-04T02:14:17.752Z",
      age: 57
    },
    registered: {
      date: "2013-03-09T01:09:46.544Z",
      age: 6
    },
    phone: "061-478-3668",
    cell: "081-879-8898",
    id: {
      name: "PPS",
      value: "1345481T"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/37.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/37.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/37.jpg"
    },
    nat: "IE"
  },
  {
    gender: "female",
    name: {
      title: "Miss",
      first: "Noemi",
      last: "Middendorp"
    },
    location: {
      street: {
        number: 5081,
        name: "Brummenboske"
      },
      city: "Sittard",
      state: "Zuid-Holland",
      country: "Netherlands",
      postcode: 10688,
      coordinates: {
        latitude: "13.7607",
        longitude: "-68.4313"
      },
      timezone: {
        offset: "+1:00",
        description: "Brussels, Copenhagen, Madrid, Paris"
      }
    },
    email: "noemi.middendorp@example.com",
    login: {
      uuid: "e406de13-06da-4940-94c4-159e783903bd",
      username: "sadswan916",
      password: "hotmail0",
      salt: "mz59iJUt",
      md5: "ede50b07204a1f3aa5c839660a7871ce",
      sha1: "1a19b280b7084e79f965f1bc4b2ea9f0c734b2c3",
      sha256: "92a84f83e9e7b3d03d50cbf3ad1c4a33b1534549a6a3b9c880ace0ef5b0ed529"
    },
    dob: {
      date: "1974-05-03T07:01:36.820Z",
      age: 45
    },
    registered: {
      date: "2017-04-27T03:24:08.495Z",
      age: 2
    },
    phone: "(655)-520-9263",
    cell: "(317)-745-7451",
    id: {
      name: "BSN",
      value: "97953619"
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/48.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/48.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/48.jpg"
    },
    nat: "NL"
  }
];
