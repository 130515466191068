import React, { useContext, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { FirebaseContext } from "../../../Firebase";
import { UserProfileContext } from "../../../context";

import { useHandleFormUpdate } from "./hooks";
import { getYears } from "./utils";

const Education = props => {
  const { docId } = useParams("docId");

  const firebase = useContext(FirebaseContext);
  const currentUserProfileData = useContext(UserProfileContext);

  const {
    handleDataChange,
    handleSubmit,
    formValues,
    submitting
  } = useHandleFormUpdate(
    firebase.db,
    currentUserProfileData ? currentUserProfileData.id : null,
    docId
  );

  // to create select with years
  const selectValues = getYears();
  const options = selectValues.map(item => (
    <option value={`${item}`}>{item}</option>
  ));

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>
            Образование <small>редактирование</small>
          </h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Год завершения</Form.Label>
              <Form.Control
                as="select"
                onChange={handleDataChange}
                name="endYear"
                value={formValues.endYear}
              >
                {options}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Полное наименование образовательного учреждения
              </Form.Label>
              <Form.Control
                type="text"
                name="schoolName"
                placeholder="Наименование образовательного учреждения"
                onChange={handleDataChange}
                value={formValues.schoolName}
              />
              <Form.Text className="text-muted">
                Укажите наименование учреждения как у вас записано в дипломе.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Тип обучения</Form.Label>
              <Form.Control
                as="select"
                onChange={handleDataChange}
                name="type"
                value={formValues.type}
              >
                <option value="">------</option>
                <option value="master">Высшее образование</option>
                <option value="ordernat">Ординатура</option>
                <option value="internat">Интернатура</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Специальность</Form.Label>
              <Form.Control
                type="text"
                name="specialization"
                placeholder="Полученная специальность"
                onChange={handleDataChange}
                value={formValues.specialization}
              />
            </Form.Group>
            <Button
              disabled={submitting}
              variant="primary"
              type="submit"
              className="mt-4"
            >
              Сохранить
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Education;
