import React, { useContext, useEffect } from "react";

import Container from "react-bootstrap/Container";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AppNavBar from "./Components/NavBar";
import AboutPage from "./Pages/ServicePage/AboutPage";
import PrivacyPolicyPage from "./Pages/ServicePage/PrivacyPolicyPage";
import ConsultationPage from "./Pages/ConsultationPage";
import ToDoDetailPage from "./Pages/ToDoPage/ToDoDetailPage";
import ToDoPage from "./Pages/ToDoPage";
import UserProfileEdit, { Education } from "./Pages/UserProfileEdit";
import { LoginPage, LoginVerifyEmailLinkPage } from "./Pages/LoginPage";
import ActiveConsultationsContext, { UserProfileContext } from "./context";

import {
  useListenUserAuthState,
  useSpecialistProfile,
  useGetActiveChatsWIthProfileId
} from "./hooks";
import { FirebaseContext } from "./Firebase";

const App = ({ location, history }) => {
  const firebase = useContext(FirebaseContext);
  const user = useListenUserAuthState(firebase.auth);

  const logout = async () => {
    if (firebase) {
      await firebase.auth.signOut();
    }
  };

  useEffect(() => {
    if (user) {
      if (
        location.pathname === "/" ||
        location.pathname === "/login/" ||
        location.pathname === "/login/verify/"
      ) {
        return history.replace(`/todo`);
      }
    }
  }, [user]);

  const userProfile = useSpecialistProfile(firebase);
  const activeConsultations = useGetActiveChatsWIthProfileId(
    userProfile ? userProfile.id : null,
    firebase
  );

  return (
    <Container>
      {!!user && userProfile === null && <Redirect to="/userprofile/edit/" />}
      <AppNavBar user={user} logout={logout} />

      <Switch>
        <Route path="/" exact component={LoginPage} />
        <Route path="/login/" exact component={LoginPage} />
        <Route path="/login/verify/" component={LoginVerifyEmailLinkPage} />

        <UserProfileContext.Provider value={userProfile}>
          <ActiveConsultationsContext.Provider value={activeConsultations}>
            <PrivateRoute path="/userprofile/edit/" state={{ user: user }}>
              <UserProfileEdit />
            </PrivateRoute>

            <PrivateRoute
              path="/userprofile/education/:docId/edit/"
              state={{ user: user }}
            >
              <Education />
            </PrivateRoute>

            <PrivateRoute path="/chats/" state={{ user: user }}>
              <ConsultationPage />
            </PrivateRoute>
            <PrivateRoute path="/todo/:todoId" state={{ user: user }} exact>
              <ToDoDetailPage />
            </PrivateRoute>
            <PrivateRoute path="/todo/" state={{ user: user }} exact>
              <ToDoPage />
            </PrivateRoute>

            <Route path="/about/" component={AboutPage} />
            <Route path="/privacypolicy/" component={PrivacyPolicyPage} />
          </ActiveConsultationsContext.Provider>
        </UserProfileContext.Provider>
      </Switch>
    </Container>
  );
};

export default withRouter(App);
