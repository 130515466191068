import React, { useEffect, useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "./Compose.css";
//import ToolbarButton from "../ToolbarButton";

export default function Compose(props) {
  const { sendMessage } = props;
  const [messageValue, setMessageValue] = useState("");
  const [mesageToSend, setMessageToSend] = useState("");

  const proxyValueFunction = val => setMessageValue(val);
  const proxySendFunction = () => {
    setMessageToSend(messageValue);
    setMessageValue("");
  };

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      proxySendFunction();
    }
  };

  useEffect(() => {
    if (!!mesageToSend) {
      sendMessage(mesageToSend);
      setMessageToSend("");
    }
  }, [mesageToSend]);

  return (
    <div className="compose">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Наберите сообщение"
          aria-label="Поле для набора сообщения"
          aria-describedby="basic-addon2"
          onKeyDown={_handleKeyDown}
          onChange={e => proxyValueFunction(e.target.value)}
          value={messageValue}
        />
        <InputGroup.Append>
          <Button
            variant={messageValue.length > 0 ? "primary" : "outline-secondary"}
            onClick={proxySendFunction}
            disabled={messageValue.length === 0}
          >
            Отправить
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}
