import React, { useState, useContext } from "react";
import { Row, Col, Alert } from "react-bootstrap";

import { FirebaseContext } from "../../Firebase";
import LoginVerifyEmailLinkPage from "./LoginVerifyEmailLinkPage";
import LoginForm from "./components/LoginForm";
import { useSubmitEmail } from "./hooks";
import { useLocalStorage } from "react-use";

const LoginPage = () => {
  const firebase = useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [localStorageEmail, setLocalStorageEmail] = useLocalStorage(
    "LS_USER_EMAIL",
    email
  );
  const { submitting, success, error } = useSubmitEmail(
    email,
    firebase,
    setLocalStorageEmail
  );

  return (
    <Row>
      <Col>
        <h1>Войти в Medkeeper</h1>
        {submitting && <p>Loading...</p>}
        {error && <Alert variant="danger">{error.message}</Alert>}
        {success && <Alert variant="success">Сслыка для входа придет вам на почту</Alert>}
        <LoginForm submitEmailValue={emailValue => setEmail(emailValue)} />
      </Col>
    </Row>
  );
};

export { LoginPage, LoginVerifyEmailLinkPage };
