import { useState, useEffect } from "react";

export const useSaveRecommendation = (
  consultationId,
  p_recommendationId,
  firebase
) => {
  const [testsRecommendation, setTestsRecommendation] = useState("");
  const [
    realConsultationRecommendation,
    setRealConsultationRecommendation
  ] = useState("");
  const [otherRecommendation, setOtherRecommendation] = useState("");

  const isDataValidToPost = () =>
    testsRecommendation.length ||
    realConsultationRecommendation.length ||
    otherRecommendation.length;

  const [recommendationId, setRecommendationId] = useState(undefined);

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setRecommendationId(p_recommendationId);
  }, [p_recommendationId]);

  const saveData = async () => {
    if (firebase && isDataValidToPost()) {
      try {
        setSubmitting(true);
        const res = await firebase.db
          .doc(
            `chats/${consultationId}/specialistRecommendation/${recommendationId}`
          )
          .update({
            recomendationData: {
              testsRecommendation,
              realConsultationRecommendation,
              otherRecommendation
            },
            isPendingSpecialist: false
          });
      } catch (e) {
        setSubmitting(false);
        setSuccess(false);
        console.log("ERR", e);
      }
      setSubmitting(false);
      setSuccess(true);
      return 0;
    }
  };

  return {
    setTestsRecommendation,
    setRealConsultationRecommendation,
    setOtherRecommendation,
    canSubmit: isDataValidToPost(),
    saveData,
    submitting,
    success
  };
};
