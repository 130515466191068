import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";

const AppNavBar = ({ user, logout }) => {
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">Medkeeper</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {!user && (
          <Nav className="mr-auto">
            <NavLink className="btn btn-primary" to="/login/">
              Войти
            </NavLink>
          </Nav>
        )}
        {user && (
          <Fragment>
            <Nav className="mr-auto">
              <Nav.Link as={NavLink} to="/todo/">
                Задачи
              </Nav.Link>
              <Nav.Link as={NavLink} to="/chats/">
                Чаты
              </Nav.Link>
              <Nav.Link as={NavLink} to="/userprofile/edit/">
                Профиль
              </Nav.Link>
            </Nav>
            <Button className="btn btn-outlined-primary" onClick={logout}>
              Выйти
            </Button>
          </Fragment>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavBar;
