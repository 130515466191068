import { useEffect, useState } from "react";

// export const useGetConsultationList = firebase => {
//   const [consultationDataList, setConsultationDataList] = useState([]);

//   useEffect(() => {
//     if (firebase && firebase.auth && firebase.auth.currentUser) {
//       firebase.db
//         .collection("chats")
//         .where("specialistId", "==", firebase.auth.currentUser.uid)
//         .where("finished", "==", false)
//         .onSnapshot(querySnapshot => {
//           let chats = [];
//           querySnapshot.forEach(function(doc) {
//             console.log("2222222doc.data(): ", doc.data());
//             chats.push({ id: doc.id, ...doc.data() });
//           });
//           setConsultationDataList(chats);
//         });
//     }
//   }, [firebase, firebase.auth, firebase.auth.currentUser]);

//   return consultationDataList;
// };

export const useGetChatMessages = (firebase, chat) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (firebase && firebase.auth && firebase.auth.currentUser && chat) {
      firebase.db
        .collection(`chats/${chat.id}/messages`)
        .orderBy("timestamp")
        .onSnapshot(querySnapshot => {
          let messages = [];
          querySnapshot.forEach(function(doc) {
            messages.push({ id: doc.id, ...doc.data() });
          });
          setMessages([...messages]);
        });
    }
  }, [firebase, firebase.auth, firebase.auth.currentUser, chat]);

  return messages;
};

export const useSendNewMessage = (firebase, message, chatId) => {
  const [sending, setSending] = useState(false);
  const [sentOk, setSentOk] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    console.log("useSendNewMessage", message, chatId);

    if (sentOk && !message) setSentOk(undefined);

    if (firebase && message && chatId) {
      setSending(true);
      console.log("useSendNewMessage Will send");
      firebase.db
        .collection(`chats/${chatId}/messages`)
        .add({
          author: firebase.auth.currentUser.uid,
          message: message,
          timestamp: firebase.FieldValue.serverTimestamp()
        })
        .then(() => {
          setSending(false);
          setSentOk(true);
          setError(undefined);
        })
        .catch(error => {
          setSending(false);
          setSentOk(false);
          setError(error);
        });
    }
  }, [firebase, message, chatId]);
  return { sending, sentOk, error };
};

export const useFinishConsultation = (firebase, chatId) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    setId(chatId);
  }, [chatId, firebase]);

  const finishConsultation = async () => {
    await firebase.db.collection(`chats/${id}/actions`).add({
      type: "FINISH_CONSULTATION",
      created_by: firebase.auth.currentUser.uid,
      timestamp: firebase.FieldValue.serverTimestamp()
    });
  };
  return finishConsultation;
};
