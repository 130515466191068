import React, { Fragment, useContext, useState, useEffect } from "react";
//import { Row, Col, ListGroup } from "react-bootstrap";

import Messenger from "../../Components/Chat/Messenger";
import { FirebaseContext } from "../../Firebase";

import {
  //useGetConsultationList,
  useGetChatMessages,
  useSendNewMessage,
  useFinishConsultation
} from "./hooks";
import ActiveConsultationsContext, { UserProfileContext } from "../../context";

const ConsultationPage = () => {
  const firebase = useContext(FirebaseContext);
  const activeConsultations = useContext(ActiveConsultationsContext);
  const userProfile = useContext(UserProfileContext);

  const [currChat, setСurrChat] = useState(undefined);
  const chatMessages = useGetChatMessages(firebase, currChat);

  const [newMessageToSend, setNewMessageToSend] = useState("");

  useEffect(() => {
    if (currChat) {
      const found = activeConsultations.find((el, idx, arr) => {
        return el.id === currChat.id;
      });
      if (!found) {
        setСurrChat(null);
      }
    }
  }, [activeConsultations, currChat]);

  const { sending, sentOk, error } = useSendNewMessage(
    firebase,
    newMessageToSend,
    currChat ? currChat.id : currChat
  );

  const onActiveConversationItemClicked = id => {
    const activeConv = activeConsultations.find(el => el.id === id);
    setNewMessageToSend("");
    setСurrChat(activeConv);
  };

  const finishConsultation = useFinishConsultation(
    firebase,
    currChat ? currChat.id : null
  );

  //const [activeChatId, setActiveChatId] = useState(undefined);

  //const consultationDataList = useGetConsultationList(firebase);

  // const listGroupItems = consultationDataList.map(item => (
  //   <ListGroup.Item action onClick={() => setActiveChatId(item.id)}>
  //     {item.id}
  //   </ListGroup.Item>
  // ));

  //const messageItems = chatMessages.map(message => <li>{message.body}</li>);

  return (
    <Fragment>
      <Messenger
        activeChats={activeConsultations}
        messageList={chatMessages}
        onChatIdChange={onActiveConversationItemClicked}
        currChat={currChat}
        sendMessage={setNewMessageToSend}
        finishConsultation={finishConsultation}
        userProfile={userProfile}
      />
    </Fragment>
  );
};

export default ConsultationPage;
//action onClick={alertClicked}
