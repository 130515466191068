import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

const useSubmitEmail = (email, firebase, setLocalStorageEmail) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    console.log(email);
    // find a corresponding specialist profile
    if (!firebase || email.length < 4) return;
    setSubmitting(true);
    const canLogin = firebase.functions.httpsCallable("canEmailSignIn");
    canLogin({ email: email, userType: "specialist" })
      .then(function(result) {
        const permission = result.data.permission;
        if (permission !== 1) {
          throw { message: "Email не авторизован для входа" };
        }
        return 0;
      })
      .then(() => {
        firebase.sendSignInLinkToEmail(email);
      })
      .then(() => {
        console.log("Succesfully sent link");
        setSubmitting(false);
        setLocalStorageEmail(email);
        setSuccess(true);
        setError(undefined);
      })
      .catch(error => {
        console.error("useSubmitEmail::error: ", error);
        setSubmitting(false);
        setSuccess(false);
        setError(error);
      });
  }, [email]);
  return { submitting, success, error };
};

export { useSubmitEmail };

const useSignInWithEmailLink = (URL, email, firebase) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (firebase)
      firebase
        .signInWithEmailLink(email, URL)
        .then(function(result) {
          window.localStorage.removeItem("emailForSignIn");
          setError(false);
          setLoading(false);
        })
        .catch(error => {
          setError(true);
          setLoading(false);
        });
  }, [email, URL, firebase]);
  return { loading, error };
};
export { useSignInWithEmailLink };
