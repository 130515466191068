import React, { Fragment, useState, useContext, useEffect } from "react";
import { Row, Col, Form, Button, Spinner, Tabs, Tab } from "react-bootstrap";
//import { Redirect } from "react-router-dom";

import { FirebaseContext } from "../../Firebase";
import { UserProfileContext } from "../../context";
import {
  useSaveSpecialistProfileData,
  useAddEducation,
  useGetProfileEducation
} from "./hooks";
//import { useSendNewMessage } from "../ConsultationPage/hooks";

import Education from "./Education";
import EducationCard from "../../Components/EducationCard";
export { Education };

const UserProfileEdit = () => {
  const firebase = useContext(FirebaseContext);
  const currentUserProfileData = useContext(UserProfileContext);

  // Education related stuff (sub document)
  const { addEducation, adding, ok, error } = useAddEducation();
  const { educationList } = useGetProfileEducation(
    firebase.db,
    currentUserProfileData ? currentUserProfileData.id : null
  );
  const educationCard = educationList.map(educationItem => {
    return <EducationCard key={educationItem.id} data={educationItem} />;
  });

  // User profile document related stuff
  // Personal
  const [nameValue, setNameValue] = useState("");
  const [sexValue, setSexValue] = useState("");
  //const [languageValue, setLanguageValue] = useState("");

  //Professional
  const [specialityValue, setSpecialityValue] = useState("");
  const [startFromProfValue, setStartFromProfValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");

  useEffect(() => {
    if (currentUserProfileData) {
      const {
        id,
        fullName,
        description,
        image,
        email,
        startFromProf,
        speciality,
        sex
      } = currentUserProfileData;
      setNameValue(fullName);
      setSpecialityValue(speciality);
      setStartFromProfValue(startFromProf);
      setSexValue(sex);
      //setLanguageValue();
      setDescriptionValue(description);
    }
  }, [currentUserProfileData]);

  const {
    setSpecialistProfileData,
    submitting,
    submitOk
  } = useSaveSpecialistProfileData({
    firebase
  });

  //if (submitOk) {
  //  return <Redirect to="/chats" />;
  //}

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Ваши данные</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="you" id="uncontrolled-tab-example">
            <Tab eventKey="you" title="Вы">
              <Form
                onSubmit={e => {
                  setSpecialistProfileData({
                    fullName: nameValue,
                    speciality: specialityValue,
                    startFromProf: startFromProfValue,
                    sex: sexValue,
                    //languageValue: languageValue,
                    description: descriptionValue
                  });
                  e.preventDefault();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <h2>Личные</h2>
                  <Form.Label>Полное имя</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Полное имя"
                    onChange={e => setNameValue(e.target.value)}
                    value={nameValue}
                  />
                  <Form.Text className="text-muted">
                    Клиенты увидят именно это имя
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Label>Пол</Form.Label>
                  <Form.Check
                    type="radio"
                    name="sex"
                    label="М"
                    checked={sexValue === "M"}
                    onChange={() => setSexValue("M")}
                  />
                  <Form.Check
                    type="radio"
                    name="sex"
                    label="Ж"
                    checked={sexValue === "F"}
                    onChange={() => setSexValue("F")}
                  />
                </Form.Group>

                <h2>Профессилнальные</h2>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Стаж работы с </Form.Label>
                  <Form.Control
                    type="date"
                    name="startFromProf"
                    placeholder="ДД.ММ.ГГ"
                    value={startFromProfValue}
                    onChange={e => {
                      setStartFromProfValue(e.target.value);
                    }}
                  />
                  <Form.Text className="text-muted">
                    Необходимо указать дату начала вашей профессиональной
                    деятельности.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox">
                  <Form.Label>Специальность</Form.Label>
                  <Form.Check
                    type="radio"
                    name="speciality"
                    label="Общий профиль"
                    checked={specialityValue === "terapevt"}
                    onChange={() => setSpecialityValue("terapevt")}
                  />
                  <Form.Check
                    type="radio"
                    name="speciality"
                    label="Педиатр"
                    checked={specialityValue === "pediatr"}
                    onChange={() => setSpecialityValue("pediatr")}
                  />
                </Form.Group>

                <Button
                  disabled={submitting}
                  variant="primary"
                  type="submit"
                  className="mt-4"
                >
                  Сохранить
                </Button>
              </Form>
            </Tab>
            <Tab eventKey="education" title="Образование">
              <Button
                className="mt-2"
                disabled={adding}
                onClick={() =>
                  addEducation(firebase.db, currentUserProfileData.id)
                }
                variant="outline-primary"
                size="lg"
                block
              >
                {adding && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Добавить образование
              </Button>

              {educationCard}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};
export default UserProfileEdit;
