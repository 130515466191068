import { useEffect, useState } from "react";

export const useSaveSpecialistProfileData = ({ firebase }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitOk, setSubmitOk] = useState(undefined);
  const [submitError, setSubmitError] = useState(undefined);

  const [specialistProfileData, setSpecialistProfileData] = useState(null);

  useEffect(() => {
    if ((specialistProfileData && firebase, firebase.auth.currentUser)) {
      setSubmitting(true);
      firebase.db
        .collection("specialist")
        .where("email", "==", firebase.auth.currentUser.email)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].ref.update({
              ...specialistProfileData,
              userUID: firebase.auth.currentUser.uid
            });
          }
        })

        .then(() => {
          setSubmitOk(true);
          setSubmitError(false);
          setSubmitting(false);
        })
        .catch(error => {
          setSubmitOk(false);
          setSubmitError(error);
          setSubmitting(false);
        });
    }
  }, [specialistProfileData]);
  return { setSpecialistProfileData, submitting, submitOk, submitError };
};

/**
 * Я потомственный врач!
Мама и папа работали медиками. бабушка тоже. Брат, сестра и кот тоже медики. Все медики!
 */

export const useAddEducation = () => {
  const [adding, setAdding] = useState(false);
  const [ok, setOk] = useState(false);
  const [error, setError] = useState(false);

  const addEducation = async (db, specialistProfileId) => {
    if (db) {
      try {
        setAdding(true);
        await db.collection(`specialist/${specialistProfileId}/education`).add({
          isActive: false
        });
        setOk(true);
        setAdding(false);
        setError(false);
      } catch (err) {
        console.error("useAddEducation:error: ", err);
        setOk(false);
        setAdding(false);
        setError(err);
      }
    }
  };

  return { addEducation, adding, ok, error };
};

export const useGetProfileEducation = (db, specialistProfileId) => {
  const [educationList, setEducationList] = useState([]);

  useEffect(() => {
    return db
      .collection(`specialist/${specialistProfileId}/education`)
      .onSnapshot(querySnapshot => {
        let tempData = [];
        querySnapshot.docs.forEach(educationItem => {
          tempData.push({ id: educationItem.id, ...educationItem.data() });
        });

        setEducationList([...tempData]);
      });
  }, [db, specialistProfileId]);

  return { educationList };
};
